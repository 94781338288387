import { inject, Pipe, PipeTransform } from '@angular/core';
import { WindowsSizeService } from '@app/services/windows-size/windows-size.service';

interface Picture {
    desktopPath: string;
    mobilePath: string;
    originalPath: string;
    tabletPath: string;
}

@Pipe({
    name: 'image',
    standalone: true,
})
export class ImagePipe implements PipeTransform {
    constructor(private windowsSizeService: WindowsSizeService) {}

    transform(value: any, ...args: unknown[]): string {
        if (!value) return '';

        const picture: Picture = value;

        if (this.windowsSizeService.checkMaxScreenSize(767)) {
            return picture.mobilePath;
        } else if (
            this.windowsSizeService.checkMinScreenSize(768) &&
            this.windowsSizeService.checkMaxScreenSize(1023)
        ) {
            return picture.tabletPath;
        } else if (this.windowsSizeService.checkMinScreenSize(1024)) {
            return picture.desktopPath;
        }

        return '';
    }
}
