import { OPERATION_COLOR_ENUM } from '@app/enums/post-section/operation_color.enum';
import { OPERATION_ICON_ENUM } from '@app/enums/post-section/operation_icon.enum';
import { OPERATION_ID_ENUM } from '@app/enums/post-section/operation_id.enum';
import { OPERATION_NAME_ENUM } from '@app/enums/post-section/operation_name.enum';
import { OPERATION_TITLE_ENUM } from '@app/enums/post-section/operation_title.enum';
import { OPERATION_TYPE_ENUM } from '@app/enums/post-section/operation_type.enum';
import { TypePublication } from '../models/post-section/type_publication.model';

export const TypePublications: TypePublication[] = [
    {
        id: OPERATION_ID_ENUM.haveIt,
        name: OPERATION_NAME_ENUM.haveIt,
        title: OPERATION_TITLE_ENUM.haveIt,
        icon: OPERATION_ICON_ENUM.haveIt,
        type: OPERATION_TYPE_ENUM.haveIt,
        color: OPERATION_COLOR_ENUM.haveIt1,
    },
    {
        id: OPERATION_ID_ENUM.wantIt,
        name: OPERATION_NAME_ENUM.wantIt,
        title: OPERATION_TITLE_ENUM.wantIt,
        icon: OPERATION_ICON_ENUM.wantIt,
        type: OPERATION_TYPE_ENUM.wantIt,
        color: OPERATION_COLOR_ENUM.wantIt1,
    },
    {
        id: OPERATION_ID_ENUM.giveYouAHand,
        name: OPERATION_NAME_ENUM.giveYouAHand,
        title: OPERATION_TITLE_ENUM.giveYouAHand,
        icon: OPERATION_ICON_ENUM.giveYouAHand,
        type: OPERATION_TYPE_ENUM.giveYouAHand,
        color: OPERATION_COLOR_ENUM.giveYouAHand1,
    },
    {
        id: OPERATION_ID_ENUM.giveMeAHand,
        name: OPERATION_NAME_ENUM.giveMeAHand,
        title: OPERATION_TITLE_ENUM.giveMeAHand,
        icon: OPERATION_ICON_ENUM.giveMeAHand,
        type: OPERATION_TYPE_ENUM.giveMeAHand,
        color: OPERATION_COLOR_ENUM.giveMeAHand1,
    },
];
